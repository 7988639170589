import {PolarSignalsFull} from '@polarsignals/icons';
import Link from 'next/link';
import {trackScheduleACallClick, trackStatuspage} from 'utils/analytics';
import Image from 'next/image';

const Footer = () => {
  return (
    <footer className="bg-black">
      <div className="container mx-auto flex w-full flex-col gap-12 py-12 px-4 md:px-8 lg:flex-row lg:gap-0 lg:py-32">
        <div className="gap-11.5 grid grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col gap-3">
            <p className="!mb-0 text-white">Company</p>
            <Link href="/about-us" className="text-brand-gray font-sans">
              About Us
            </Link>
            <Link href="/blog" className="text-brand-gray font-sans">
              Blog
            </Link>
            <Link href="/tos" className="text-brand-gray font-sans">
              Terms of Service
            </Link>
            <Link href="/privacy" className="text-brand-gray font-sans">
              Privacy Policy
            </Link>
            <Link href="/working-at-polar-signals" className="text-brand-gray font-sans">
              Working at Polar Signals
            </Link>
          </div>

          <div className="flex flex-col gap-3">
            <p className="!mb-0 text-white">Support</p>
            <Link href="/pricing" className="text-brand-gray font-sans">
              Pricing
            </Link>
            <Link href="/#faq" className="text-brand-gray font-sans">
              FAQs
            </Link>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://polarsignals.com/docs"
              className="text-brand-gray font-sans"
            >
              Docs
            </a>
            <a
              href="mailto:support@polarsignals.com"
              target="_blank"
              rel="noreferrer"
              className="text-brand-gray font-sans"
            >
              Contact Us
            </a>
            <Link
              href="/schedule-a-call"
              className="text-brand-gray font-sans"
              onClick={() => trackScheduleACallClick('footer')}
            >
              Schedule a call
            </Link>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://polarsignals.instatus.com"
              className="text-brand-gray font-sans"
              onClick={() => trackStatuspage('footer')}
            >
              Status
            </a>
          </div>

          <div className="flex flex-col gap-3">
            <p className="!mb-0 text-white">Product</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cloud.polarsignals.com"
              className="text-brand-gray font-sans"
            >
              Polar Signals Cloud
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://pprof.me"
              className="text-brand-gray font-sans"
            >
              pprof.me
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://parca.dev/"
              className="text-brand-gray font-sans"
            >
              Parca
            </a>
          </div>

          <div className="flex flex-col gap-3">
            <p className="!mb-0 text-white">Social</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/PolarSignalsIO"
              className="text-brand-gray font-sans"
            >
              Twitter
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/polarsignals"
              className="text-brand-gray font-sans"
            >
              GitHub
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/knw3u5X9bs"
              className="text-brand-gray font-sans"
            >
              Discord
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@PolarSignalsIO"
              className="text-brand-gray font-sans"
            >
              YouTube
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center justify-between gap-3 lg:ml-auto lg:items-end lg:gap-0">
          <div className="flex flex-col items-center lg:items-end gap-4">
            <Link href="/">
              <PolarSignalsFull width="200" className="text-white" />
            </Link>
            <Image
              src="/logos/soc.png"
              alt="AICPA SOC Logo"
              width={80}
              height={80}
              className="object-contain"
            />
          </div>

          <p className="text-brand-gray !mb-0 text-center font-sans text-base font-normal lg:text-right">
            Copyright © {new Date().getFullYear()} Polar Signals. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
