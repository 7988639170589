import CompareArrows from './assets/compare-arrows.svg';
import PolarSignals from './assets/logo.svg';
import PolarSignalsIconSolid from './assets/logo-icon-solid.svg';
import PolarSignalsFull from './assets/logo-full.svg';
import PolarSignalsFaded from './assets/logo-faded.svg';
import CoreOS from './assets/coreos.svg';
import Cortex from './assets/cortex.svg';
import DigitalOcean from './assets/digitalocean.svg';
import HashiCorp from './assets/hashicorp.svg';
import Prometheus from './assets/prometheus.svg';
import RedHat from './assets/red-hat.svg';
import Thanos from './assets/thanos.svg';
import Checkmark from './assets/checkmark.svg';
import RedX from './assets/red-x.svg';
import GitHub from './assets/github.svg';
import LinkedIn from './assets/linkedin.svg';
import Twitter from './assets/twitter.svg';
import ArrowForward from './assets/arrow-forward.svg';
import StripesAngular from './assets/stripes-angular.svg';
import StripesStraight from './assets/stripes-straight.svg';
import CaretUp from './assets/caret-up.svg';
import CaretDown from './assets/caret-down.svg';
import Hamburger from './assets/hamburger.svg';
import Parca from './assets/parca.svg';
import YCombinator from './assets/y-combinator.svg';
import Reddit from './assets/reddit.svg';
import Close from './assets/close.svg';

export {
  PolarSignals,
  PolarSignalsFull,
  PolarSignalsFaded,
  PolarSignalsIconSolid,
  CompareArrows,
  CoreOS,
  Cortex,
  DigitalOcean,
  HashiCorp,
  Prometheus,
  RedHat,
  Thanos,
  Checkmark,
  RedX,
  GitHub,
  LinkedIn,
  Twitter,
  ArrowForward,
  StripesAngular,
  StripesStraight,
  CaretUp,
  Hamburger,
  Parca,
  YCombinator,
  Reddit,
  Close,
  CaretDown,
};
