import {Fragment, useRef} from 'react';
import {Popover, Transition} from '@headlessui/react';
import {Icon} from '@iconify/react';
import Link from 'next/link';

import Warning from './assets/warning.svg';
import Magic from './assets/magic.svg';
import Money from './assets/money.svg';

const links = [
  {
    name: 'Understand Incidents',
    href: '/use-cases/understand-incidents',
    icon: <Warning />,
  },
  {
    name: 'Optimize Performance',
    href: '/use-cases/optimize-performance',
    icon: <Magic />,
  },
  {
    name: 'Save Infrastructure Costs',
    href: '/use-cases/save-infrastructure-costs',
    icon: <Money />,
  },
];

const UseCases = () => {
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const handleHover = () => {
    menuButtonRef.current?.click();
  };

  const handleHoverLeave = () => {
    menuButtonRef.current?.click();
  };

  return (
    <Popover className="relative">
      <Popover.Button className="outline-none" ref={menuButtonRef} onMouseEnter={handleHover}>
        <div className="p-5">
          <div className="flex items-center justify-center">
            <span>Use Cases</span>
            <Icon icon="ph:caret-down-bold" className="ml-1 h-[15px] w-auto" />
          </div>
        </div>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 w-[350px]" onMouseLeave={handleHoverLeave}>
          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="relative flex flex-col gap-2 bg-[#F9FAFB] px-4 py-6">
              {links.map(link => (
                <Link
                  key={link.name}
                  href={link.href}
                  className="flex items-center rounded-lg px-2 py-3 transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-indigo-600 focus-visible:ring-opacity-50"
                >
                  <div className="flex h-6 w-6 items-center justify-center text-indigo-600">
                    {link.icon}
                  </div>
                  <div className="ml-4">
                    <div className="text-base font-medium text-gray-900">{link.name}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default UseCases;
